<template>
  <div class="placeholder mb-4 overflow-hidden rounded-md bg-white">
    <div class="relative aspect-square h-24" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.placeholder {
  position: relative;
  background-color: #fff;
  z-index: 1;
  overflow: hidden;
  aspect-ratio: 1 / 0.8;
  min-height: 6rem;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -45%;
    height: 100%;
    width: 45%;
    background-image: linear-gradient(to left, rgba(239, 239, 239, .05), rgba(239, 239, 239, .3), rgba(239, 239, 239, .6), rgba(239, 239, 239, .3), rgba(239, 239, 239, .05));
    animation: loading 1s infinite;
    z-index: 2;
  }
}

@keyframes loading {
  0% {
    left: -45%;
  }
  100% {
    left: 100%;
  }
}
</style>
