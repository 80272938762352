<template>
  <div
    class="relative flex w-full flex-col text-center"
    @click="action"
  >
    <div>
      <font-awesome-icon
        :icon="icon"
        size="3x"
        fixed-width
        class="text-primary"
      />
    </div>
    <div class="flex flex-col items-center px-3 pt-5">
      <span class="whitespace-nowrap leading-7 text-grey-dark">
        {{ subtitle }}
      </span>
      <span class="text-lg font-semibold leading-6 text-primary">
        {{ title }}
      </span>
    </div>
    <button
      v-if="explanation"
      v-tooltip="{content: info, html: true}"
      aria-label="Bekijk info"
    >
      <font-awesome-icon
        :icon="['fas', 'info-circle']"
        class="smooth-transition hover:text-primary focus:text-primary"
      />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: Array,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    explanation: {
      type: String,
      default: null,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const sectorCodesOpen = useState(OPEN_SECTOR_CODES, () => false);

    return {
      sectorCodesOpen,
    };
  },
  computed: {
    info() {
      return `<div
        class="relative bg-white rounded-md border border-grey-light shadow-lg px-6 py-4 z-10"
        style="max-width: 12rem;"
      >
        <h3 class="text-left text-primary font-semibold pb-3">${this.title}</h3>
        <div class="text-left">
          ${this.explanation}
        </div>
      </div>`;
    },
  },
  methods: {
    toggleInfo() {
      this.info = !this.info;
    },
    action() {
      if (this.clickable) {
        this.sectorCodesOpen.value = true;
      }
    },
  },
};
</script>

<style scoped>
.placement-right {
  right: -20rem;
}

.placement-left {
  left: -20rem;
}

.w-80 {
  width: 20rem;
}
</style>
