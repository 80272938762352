<template>
  <div>
    <div class="hidden grid-cols-4 gap-4 pb-12 pt-16 md:grid">
      <home-icon-card
        v-for="(card, index) in iconStats"
        :key="card.title"
        :title="card.title"
        :subtitle="card.value"
        :content="card.content"
        :explanation="card.explanation"
        :icon="['fas', card.icon]"
        :index="index"
        :class="{'cursor-pointer' : card.clickable}"
        :clickable="card.clickable"
      />
    </div>

    <div v-if="featuredProductsLoading">
      <h3 class="mb-5 mt-3 text-2xl font-semibold text-primary">
        Uitgelicht
      </h3>

      <div class="grid grid-cols-1 gap-4 lg:grid-cols-4">
        <card-placeholder
          v-for="index in [1, 2, 3, 4]"
          :key="index"
        />
      </div>
    </div>
    <home-list-of-products
      v-else
      :products="highlights"
      title="Uitgelicht"
    />

    <home-list-of-products
      :products="trending"
      title="Trending"
    />
    <home-list-of-products
      :products="recentAdded"
      title="Toegevoegd"
    />
  </div>
</template>

<script setup lang="ts">
definePageMeta({
  auth: false,
  layout: 'with-sidebar',
});

const {result: featuredProductsResult, loading: featuredProductsLoading} = useFeaturedProductsQuery();
const highlights = computed(() => featuredProductsResult.value?.featuredProducts ?? []);

const {result: trendingProductsResult} = useTrendingProductsQuery();
const trending = computed(() => trendingProductsResult.value?.trendingProducts ?? []);

const {result: recentProductsResult} = useRecentProductsQuery();
const recentAdded = computed(() => recentProductsResult.value?.recentProducts ?? []);

const {result: indexStatsResult} = useIndexStatsQuery();
const iconStats = computed(() => indexStatsResult.value?.indexStats ?? []);
</script>
