<template>
  <div
    v-show="products.length"
    class="pb-10 md:pb-0"
    :class="{'pt-5': organisationPage}"
  >
    <div class="flex flex-col justify-between sm:flex-row sm:items-center">
      <h3 class="mt-3 text-2xl font-semibold text-primary">
        {{ title }}
      </h3>

      <div
        v-if="organisationPage || projectPage"
        class="flex items-center gap-3"
      >
        <nuxt-link
          :to="{name: 'materialen', query: {...materialQuery, show_map: 'true'}}"
          class="hidden px-4  text-primary md:block"
        >
          <font-awesome-icon
            :icon="['fas', 'map']"
            class="text-primary"
          />
          <span
            class="map-text smooth-transition border-b-2 border-solid border-transparent text-sm font-bold"
          >
            Toon op kaart
          </span>
        </nuxt-link>
        <nuxt-link
          :to="{name: 'materialen', query: materialQuery}"
          class="btn btn-secondary items-center gap-2"
        >
          Alle materialen
          <font-awesome-icon
            :icon="['fas', 'arrow-right']"
            size="sm"
            class="mr-2 text-white"
          />
        </nuxt-link>
      </div>
    </div>
    <client-only>
      <Swiper
        :ref="`productcard-${uniqId}`"
        v-bind="swiperOptions"
        :class="organisationPage ? 'pb-2' : 'pb-10'"
      >
        <SwiperSlide
          v-for="product in products"
          :key="product.id"
        >
          <product-card
            :likable="false"
            :product="product"
          />
        </SwiperSlide>
        <template #pagination>
          <div
            v-show="showPagination"
            :class="`swiper-pagination swiper-pagination-${uniqId}`"
          />
        </template>
      </Swiper>
    </client-only>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    slug: {
      type: String,
      default: '',
    },
    organisationPage: {
      type: Boolean,
      default: false,
    },
    projectPage: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    uniqId: Math.floor(Math.random() * 10000000),
    swiperOptions: {
      slidesPerView: 4,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: 'swiper-pagination-',
      },
      breakpoints: {
        1024: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 2,
        },
        320: {
          slidesPerView: 1,
        },
      },
    },
    showPagination: false,
  }),
  computed: {
    materialQuery() {
      if (this.organisationPage || this.projectPage) {
        return this.organisationPage ? {organisation_slug: this.slug} : {project_slug: this.slug};
      }
      return {};
    },
  },
  created() {
    this.swiperOptions.pagination = {
      el: '.swiper-pagination-' + this.uniqId,
    };
  },
  mounted() {
    this.setShowPagination();
    window.addEventListener('resize', () => {
      this.setShowPagination();
    });
  },
  methods: {
    setShowPagination() {
      const windowWidth = window.innerWidth;
      const breakpointWidths = Object.keys(this.swiperOptions.breakpoints);
      const breakpointWidth = breakpointWidths.find((width, index) => {
        return index + 1 === breakpointWidths.length || (windowWidth >= width && windowWidth < breakpointWidths[index + 1]);
      });

      this.showPagination = (this.products.length / this.swiperOptions.breakpoints[breakpointWidth].slidesPerView) > 1;
    },
  },
};
</script>

<style scoped>
:deep(.swiper) {
  @apply pt-5 pb-10;
}
</style>

<style src="swiper/css" />
